// material-ui
import {
    Avatar,
    Button,
    Grid,
    Stack,
    TextField,
    MenuItem,
    InputLabel,
    Select,
    FormControl,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Link,
    CardContent,
    useTheme
} from '@mui/material';

// project imports
import useAuth from 'hooks/useAuth';
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import MuiTypography from '@mui/material/Typography';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';

import { useState } from 'react';

// assets
import ProfilePic from 'assets/images/careteam/dr_frank_ellison_specialist.png';

// third-party
import { useIntl, FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';

// ==============================|| PROFILE ||============================== //

const MyProfile = () => {
    const theme = useTheme();
    const { user } = useAuth();
    const intl = useIntl();
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const formik = useFormik({
        initialValues: {
            gender: '',
            preferredLanguage: ''
        }
    });
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={6} md={3}>
                <CardContent sx={cardStyle}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} align="center">
                            <Avatar alt="User 1" src={ProfilePic} sx={{ borderRadius: '50%', width: 150, height: 150 }} />
                        </Grid>
                        <Grid item xs={12} align="center">
                            {/* <Button variant="text" href="#contained-buttons" fullWidth> */}
                            <MuiTypography
                                variant="body2"
                                color="primary"
                                component={Link}
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                                target="_blank"
                                display="block"
                                underline="hover"
                                gutterBottom
                            >
                                <FormattedMessage id="Change_Profile_Picture" />
                            </MuiTypography>
                            {/* </Button> */}
                        </Grid>
                    </Grid>
                </CardContent>
            </Grid>
            <Grid item xs={6} md={9}>
                <Grid container direction="column" spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <SubCard title={intl.formatMessage({ id: 'Basic_Information' })} sx={cardStyle}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12} sm={4}>
                                    <TextField fullWidth select defaultValue="dr" id="title" name="title" label="Title">
                                        <MenuItem value="dr">Dr</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        id="firstName"
                                        sx={{ color: '#fff' }}
                                        fullWidth
                                        label={intl.formatMessage({ id: 'First_Name' })}
                                        defaultValue="Frank"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField id="middleName" fullWidth label={intl.formatMessage({ id: 'Middle_Name' })} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        id="lastName"
                                        fullWidth
                                        label={intl.formatMessage({ id: 'Last_Name' })}
                                        defaultValue="Ellison"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            renderInput={(props) => <TextField size="small" {...props} helperText="" fullWidth />}
                                            label={intl.formatMessage({ id: 'Date_Of_Birth' })}
                                            value={dateOfBirth}
                                            onChange={(newValue) => {
                                                setDateOfBirth(newValue);
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="gender-select">
                                            <FormattedMessage id="Gender" />
                                        </InputLabel>
                                        <Select
                                            labelId="gender-select"
                                            id="gender"
                                            name="gender"
                                            value={formik.values.gender}
                                            onChange={formik.handleChange}
                                            label={intl.formatMessage({ id: 'Gender' })}
                                        >
                                            <MenuItem value={0}>
                                                <FormattedMessage id="Male" />
                                            </MenuItem>
                                            <MenuItem value={1}>
                                                <FormattedMessage id="Female" />
                                            </MenuItem>
                                            <MenuItem value={2}>
                                                <FormattedMessage id="Other" />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="preferredLanguage-select">
                                            <FormattedMessage id="Preferred_Language" />
                                        </InputLabel>
                                        <Select
                                            labelId="preferredLanguage-select"
                                            id="preferredLanguage"
                                            name="preferredLanguage"
                                            value={formik.values.preferredLanguage}
                                            onChange={formik.handleChange}
                                            label={intl.formatMessage({ id: 'Preferred_Language' })}
                                        >
                                            <MenuItem value={0}>
                                                <FormattedMessage id="Preferred_Language_English" />
                                            </MenuItem>
                                            <MenuItem value={1}>
                                                <FormattedMessage id="Preferred_Language_French" />
                                            </MenuItem>
                                            <MenuItem value={2}>
                                                <FormattedMessage id="Preferred_Language_Chineese" />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField id="email" fullWidth label={intl.formatMessage({ id: 'Email' })} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField id="secondaryEmail" fullWidth label={intl.formatMessage({ id: 'Secondary_Email' })} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField id="mobile" fullWidth label={intl.formatMessage({ id: 'Mobile' })} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row">
                                        <AnimateButton>
                                            <Button variant="contained">
                                                {' '}
                                                <FormattedMessage id="Save" />
                                            </Button>
                                        </AnimateButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </SubCard>
                    </Grid>
                    <Grid item xs={12}>
                        <SubCard title={intl.formatMessage({ id: 'Preferred_Mode_Of_Communication' })} sx={cardStyle}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={<Checkbox defaultChecked name="checkedMobile" color="primary" />}
                                                    label={intl.formatMessage({ id: 'Mobile' })}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={<Checkbox defaultChecked name="checkedEmail" color="primary" />}
                                                    label={intl.formatMessage({ id: 'Email' })}
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row">
                                        <AnimateButton>
                                            <Button variant="contained">
                                                <FormattedMessage id="Save" />
                                            </Button>
                                        </AnimateButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </SubCard>
                    </Grid>
                    <Grid item xs={12}>
                        <SubCard title={intl.formatMessage({ id: 'Notifications' })} sx={cardStyle}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={<Checkbox defaultChecked name="checkedGlobalNotifications" color="primary" />}
                                                    label={intl.formatMessage({ id: 'Global_Notifications' })}
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row">
                                        <AnimateButton>
                                            <Button variant="contained">
                                                <FormattedMessage id="Save" />
                                            </Button>
                                        </AnimateButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </SubCard>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MyProfile;
