import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Messages from 'views/messages';
import AccountSettings from 'views/accountsettings';

const SpecialistDashboard = Loadable(lazy(() => import('views/dashboard')));
const OrganizationSettings = Loadable(lazy(() => import('views/organizationsettings')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <SpecialistDashboard />
        },
        {
            path: '/dashboard',

            element: <SpecialistDashboard />
        },
        {
            path: '/messaging',
            element: <Messages />
        },
        {
            path: '/organizationsettings',

            element: <OrganizationSettings />
        },
        {
            path: '/accountSettings',
            element: <AccountSettings />
        }
    ]
};

export default MainRoutes;
