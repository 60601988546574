import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const SpecialistVerification = Loadable(lazy(() => import('views/onboarding')));
const SpecialistVerificationCode = Loadable(lazy(() => import('views/onboarding/SpecialistVerificationCode')));
const SpecialistSignUp = Loadable(lazy(() => import('views/onboarding/SpecialistSignUp')));
const SpecialistCredentials = Loadable(lazy(() => import('views/onboarding/SpecialistCredentials')));

const SpecialistSecurityQuestions = Loadable(lazy(() => import('views/onboarding/SpecialistSecurityQuestions')));
const SpecialistUserAgreement = Loadable(lazy(() => import('views/onboarding/SpecialistUserAgreement')));
const SpecialistPrivacyPolicy = Loadable(lazy(() => import('views/onboarding/SpecialistPrivacyPolicy')));

const ForgotUsername = Loadable(lazy(() => import('views/forgotusername')));
const ForgotMailSent = Loadable(lazy(() => import('views/forgotusername/ForgotMailSent')));
const ForgotPassword = Loadable(lazy(() => import('views/forgotpassword')));
const SpecialistPwdVerificationMethod = Loadable(lazy(() => import('views/forgotpassword/SpecialistPwdVerificationMethod')));
const SpecialistPwdVerificationCode = Loadable(lazy(() => import('views/forgotpassword/SpecialistPwdVerificationCode')));
const ForgotPasswordSecurityQuestion = Loadable(lazy(() => import('views/forgotpassword/ForgotPasswordSecurityQuestion')));
const ResetPassword = Loadable(lazy(() => import('views/forgotpassword/ResetPassword')));
const PasswordChanged = Loadable(lazy(() => import('views/forgotpassword/PasswordChanged')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/forgotpassword',
            element: <ForgotPassword />
        },
        {
            path: '/forgotusername',
            element: <ForgotUsername />
        },
        {
            path: '/forgotpassword/verificationmethod',
            element: <SpecialistPwdVerificationMethod />
        },
        {
            path: '/forgotpassword/specialistverificationcode',
            element: <SpecialistPwdVerificationCode />
        },
        {
            path: '/forgotpassword/securityquestion',
            element: <ForgotPasswordSecurityQuestion />
        },
        {
            path: '/forgotpassword/resetpassword',
            element: <ResetPassword />
        },
        {
            path: '/forgotpassword/passwordchanged',
            element: <PasswordChanged />
        },
        {
            path: '/forgotusername/mailsent',
            element: <ForgotMailSent />
        },
        {
            path: '/onboarding',
            element: <SpecialistVerification />
        },
        {
            path: '/onboarding/specialistverification',
            element: <SpecialistVerificationCode />
        },
        {
            path: '/onboarding/specialistsignup',
            element: <SpecialistSignUp />
        },
        {
            path: '/onboarding/specialistcredentials',
            element: <SpecialistCredentials />
        },
        {
            path: '/onboarding/specialistsecurityquestions',
            element: <SpecialistSecurityQuestions />
        },
        {
            path: '/onboarding/specialistuseragreement',
            element: <SpecialistUserAgreement />
        },
        {
            path: '/onboarding/specialistprivacypolicy',
            element: <SpecialistPrivacyPolicy />
        }
    ]
};

export default LoginRoutes;
