// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconDashboard,
    IconHeart,
    IconMail,
    IconSettings,
    IconBuildingHospital,
    IconFile,
    IconUsers
} from '@tabler/icons';

import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import ArticleIcon from '@mui/icons-material/Article';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

// constant
const icons = {
    ManageAccountsIcon,
    ArticleIcon,
    GroupIcon,
    DashboardIcon,
    ApartmentIcon,
    VolunteerActivismIcon,
    IconBrandChrome,
    IconDashboard,
    IconHelp,
    IconSitemap,
    IconHeart,
    IconMail,
    IconSettings,
    IconBuildingHospital,
    IconFile,
    IconUsers,
    AccountBalanceIcon,
    RuleFolderIcon,
    LocalHospitalIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'messaging',
            title: 'Messaging',
            type: 'item',
            url: '/messaging',
            icon: icons.IconMail,
            breadcrumbs: false
        },
        {
            id: 'organizationsettings',
            title: 'Organization Settings',
            type: 'item',
            url: '/organizationsettings',
            icon: icons.ApartmentIcon,
            breadcrumbs: false
        },
        {
            id: 'accountsettings',
            title: 'Account Settings',
            type: 'item',
            url: '/accountsettings',
            icon: icons.ManageAccountsIcon,
            breadcrumbs: false
        }
    ]
};

export default other;
