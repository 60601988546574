// material-ui
import { useTheme } from '@mui/material/styles';
import { CardMedia, Stack, Typography } from '@mui/material';

// project imports
import * as constant from 'store/constant';
import imageEmpty from 'assets/images/maintenance/empty.svg';
import imageDarkEmpty from 'assets/images/maintenance/empty-dark.svg';

// ==============================|| NO/EMPTY MAIL ||============================== //

const MessageEmpty = () => {
    const theme = useTheme();

    return (
        <Stack alignItems="center" spacing={constant.gridSpacing} sx={{ mt: 3 }}>
            <CardMedia
                component="img"
                image={theme.palette.mode === 'dark' ? imageDarkEmpty : imageEmpty}
                title="Slider5 image"
                sx={{ maxWidth: 320 }}
            />
            <Stack spacing={1}>
                <Typography variant="h3" color="inherit" component="div">
                    You don&apos;t have any messages
                </Typography>
            </Stack>
        </Stack>
    );
};

export default MessageEmpty;
